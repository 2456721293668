import mailsService from '@/services/http/mails.service';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Actually a search
    async fetchMails({ rootState }, data) {
      const res = await mailsService.fetchMails(data);
      return res;
    },
    async addMails({ rootState }, data) {
      const res = await mailsService.addMails(data);
      return res;
    },
    async getMails({ rootState }, data) {
      const res = await mailsService.getMails(data);
      return res;
    },
    async fetchMailTypes({ rootState }, data) {
      const res = await mailsService.fetchMailTypes(data);
      return res;
    },

    async deleteMails({ rootState }, data) {
      const res = await mailsService.deleteMails(data);
      return res;
    },

  },
};
