<template>
  <div>

    <b-card class="invoice-preview-card">
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto"> {{ $t('inverstor_mails.detail_title') }} <br /></h2>
        <b-button variant="outline-primary" @click="$router.push({ name: 'config-invertors-mails-list' })"
          class="d-flex align-items-center">
          <i class="las la-arrow-left mr-50"></i>
          <span>{{ $t('button.back_to_list') }}</span>
        </b-button>
      </div>
    </b-card>
    <b-card v-if="isCreatingProduct == true && form && form != null">
   <b-col cols="12" class="pt-2">
              <b-skeleton animation="fade" width="30%"></b-skeleton>
              <b-skeleton animation="fade" width="35%"></b-skeleton>
              <b-skeleton animation="fade" width="30%"></b-skeleton>
            </b-col>
            <hr >
            <b-col cols="12">
              <b-skeleton animation="fade" width="50%"></b-skeleton>
            </b-col>
         
            <hr >
            <b-col cols="12" class="pt-2 ">
              <b-skeleton animation="fade" width="40%" ></b-skeleton>
              <b-skeleton animation="fade" width="50%" ></b-skeleton>
              <b-skeleton animation="fade" width="55%" ></b-skeleton>
            </b-col>
          </b-card>
    <b-card v-else>
      <b-row class="px-1  d-flex align-items-center">

            <b-col cols="12">
              <span><strong>{{ $t('profil.main_info_fullname') }}: </strong> <span> {{ form.fullname }}</span></span>
            </b-col>
            <b-col cols="12">
              <span><strong>{{ $t('profil.main_info_email') }}: </strong> <span> {{ form.email }}</span></span>
            </b-col>
            <b-col cols="12">
              <span><strong>{{ $t('table_columns.phone') }}: </strong> <span> {{ form.contact }}</span></span>

            </b-col>

      </b-row>
      <hr class="" />
      <b-row class="px-1  ">
        <b-col cols="12">
          
<span><strong>{{ $t('table_columns.subject') }}: </strong> <span> {{ form.subject }}</span></span>
        </b-col>
      </b-row>
      <hr class="" />
      <b-row class="px-1">
        <b-col cols="12">
        <span>
          <strong>{{ $t('table_columns.message') }}: </strong> <span> {{ form.content }}</span>
        </span>
      </b-col>

      </b-row>
    </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTags,
  BSpinner, BBadge,
  BCardHeader,BSkeleton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueEditor } from 'vue2-editor'

import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import mailsStoreModule from '@/store/mails'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTags,
    VueEditor,
    vSelect,
    BSpinner, BBadge, BCardHeader,BSkeleton,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'mails', module: mailsStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    return {
      requiredStoreModules,
    }
  },

  data() {
    return {

      currentId: null,
      form: {

      },
      isCreatingProduct: false,

    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        await this.loadMail()
      },
    },

  },

  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },
  mounted() {
   
  },
  methods: {
    ...mapActions('mails', {
      action_getMails: 'getMails',
    }),
    loadMail() {
      this.isCreatingProduct = true
      this.action_getMails(this.$route.params.id)
        .then(response => {
          this.isCreatingProduct = false
          this.form = response.data.data
          console.log('this.formload: ', this.form)

        })
        .catch(error => {
          // console.log(error)
          this.isCreatingProduct = false
          // this.errored = true
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Une erreur est survenue',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: '',
            },
          })
        })
    },
    updateFaq() {
      this.isCreatingProduct = true
      this.currentId = this.$route.params.id;
      console.log('this.formupdate: ', this.form)
      this.action_updateFaq({
        id: this.currentId,
        data: this.form,
      })
        .then(response => {
          this.isCreatingProduct = false
          this.isCreatingFiche = false;
          this.currentId = null;
          this.$router.replace({ name: "configs-faqs-list" }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Création réussie',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: '',
              },
            })
          })
        })
        .catch(error => {
          // console.log(error)
          this.isCreatingProduct = false
          // this.errored = true
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Une erreur est survenue',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: '',
            },
          })
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.invoice-preview-wrapper {
  .row.invoice-preview {
    .col-md-8 {
      max-width: 100%;
      flex-grow: 1;
    }

    .invoice-preview-card {
      .card-body:nth-of-type(2) {
        .row {
          >.col-12 {
            max-width: 50% !important;
          }

          .col-12:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  // Action Right Col
  .invoice-actions {
    display: none;
  }
}
</style>