import { BaseService } from "./base.service";

export class MailsService extends BaseService {
  constructor() {
    super();
  }

  fetchMails(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/mails/search", data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  addMails(post) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/mails", post)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
  getMails(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/mails/${id}`, {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }



  fetchMailTypes() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/mails/types`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }


  deleteMails(id) {
    console.log('id :>> ', id)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`/mails/${id}`, {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }



}

export default new MailsService();
